import React from 'react';
import { InjectedTranslateProps, translate } from 'yoshi-flow-editor-runtime';
import { st, classes } from './SchedulingAgenda.st.css';
import { DailySessions } from './DailySessions/DailySessions';
import {
  SchedulingSectionStatus,
  SchedulingSectionViewModel,
} from '../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import { Loader } from './Loader/Loader';
import { EmptyState } from './EmptyState/EmptyState';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';
import settingsParams from '../BookingServicePage/settingsParams';
import Divider from './Divider/Divider';
import { useLoadAllSessions } from './useLoadAllSessions';
import { useTPAComponentsConfig } from '../BookingServicePage/Widget/useTPAComponentsConfig';

export type SchedulingAgendaProps = InjectedTranslateProps & {
  viewModel?: SchedulingSectionViewModel;
};
export const SchedulingAgendaComponent: React.FC<SchedulingAgendaProps> = ({
  viewModel,
  t,
}) => {
  const settings = useSettings();
  const { rtl = false } = useTPAComponentsConfig();
  const totalNumberOfSessions = viewModel?.schedulingDaysViewModel?.length || 0;
  const { numberOfSession, LoadAllSessions } = useLoadAllSessions({
    defaultValue: settingsParams.scheduleDays,
    totalNumberOfSessions,
  });

  const componentByStatus = () => {
    switch (viewModel?.status) {
      case SchedulingSectionStatus.LOADING:
        return <Loader />;
      case SchedulingSectionStatus.EMPTY:
        return emptyState();
      case SchedulingSectionStatus.FAILED:
        return (
          <EmptyState
            message={t('app.widget.uou-messages.scheduling-error')}
            dataHook={'error-message'}
          />
        );
      case SchedulingSectionStatus.SUCCESS:
        return dailySessions();
      default:
        return <Loader />;
    }
  };
  const emptyState = () => {
    const daysAhead = settings.get(settingsParams.scheduleDays);
    const emptyStateMessage =
      settings.get(settingsParams.scheduleEmptyStateText) ||
      t('app.scheduling.empty-state', { daysAhead });
    return <EmptyState message={emptyStateMessage} dataHook={'empty-state'} />;
  };

  const showDivider = (index) => {
    const lastElementIndex =
      Math.min(numberOfSession, totalNumberOfSessions) - 1;
    return index !== lastElementIndex ? <Divider /> : null;
  };

  const dailySessions = () => {
    return (
      <>
        {viewModel?.schedulingDaysViewModel
          ?.slice(0, numberOfSession)
          .map((scheduleDay, index) => (
            <>
              <DailySessions viewModel={scheduleDay} key={index} />
              {showDivider(index)}
            </>
          ))}
        <LoadAllSessions />
      </>
    );
  };
  return (
    <div
      className={st(classes.root, { isRTL: rtl })}
      data-hook="scheduling-agenda"
    >
      {componentByStatus()}
    </div>
  );
};

export const SchedulingAgenda = translate()(SchedulingAgendaComponent);
