import React, { useRef } from 'react';
import {
  InjectedExperimentsProps,
  InjectedTranslateProps,
} from 'yoshi-flow-editor-runtime';
import { withExperiments } from '@wix/wix-experiments-react';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';
import { translate } from 'react-i18next';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { MediaImage } from 'wix-ui-core/media-image';
import { HeaderViewModel } from '../../../../service-page-view-model/header-view-model/headerViewModel';
import { ImagePositionOptions, MainComponents } from '../../types';
import { RenderLocationProvider } from '../useRenderLocation';
import { st, classes } from './Header.st.css';
import settingsParams from '../../settingsParams';
import BookButton from '../BookButton/BookButton';
import { useTPAComponentsConfig } from '../useTPAComponentsConfig';
import { useSizeListener } from '../useSizeListener';

type HeaderProps = InjectedExperimentsProps &
  InjectedTranslateProps & {
    viewModel: HeaderViewModel;
    className?: string;
  };

const Header: React.FC<HeaderProps> = ({ viewModel, className }) => {
  const settings = useSettings();
  const headerRef = useRef<HTMLDivElement>(null);
  const { mobile = false } = useTPAComponentsConfig();
  const imagePositionToFocalPoint = {
    [ImagePositionOptions.BOTTOM]: { x: 50, y: 100 },
    [ImagePositionOptions.BOTTOM_LEFT]: { x: 0, y: 100 },
    [ImagePositionOptions.BOTTOM_RIGHT]: { x: 100, y: 100 },
    [ImagePositionOptions.LEFT]: { x: 0, y: 50 },
    [ImagePositionOptions.RIGHT]: { x: 100, y: 50 },
    [ImagePositionOptions.TOP]: { x: 50, y: 0 },
    [ImagePositionOptions.TOP_LEFT]: { x: 0, y: 0 },
    [ImagePositionOptions.TOP_RIGHT]: { x: 100, y: 0 },
    [ImagePositionOptions.MIDDLE]: { x: 50, y: 50 },
  };
  const headerDimensions = headerRef.current?.getClientRects()?.[0];
  useSizeListener();

  const serviceImage = () =>
    settings.get(settingsParams.headerImageVisibility) &&
    viewModel?.imageUri &&
    headerDimensions ? (
      <div className={classes.media}>
        <MediaImage
          key={`${JSON.stringify(
            settings.get(settingsParams.headerImagePosition),
          )} ${headerDimensions.height} ${headerDimensions.width}`}
          mediaPlatformItem={{
            height: headerDimensions.height,
            uri: viewModel.imageUri,
            width: headerDimensions.width,
            options: {
              focalPoint:
                imagePositionToFocalPoint[
                  settings.get(settingsParams.headerImagePosition)
                ],
            },
          }}
          height={headerDimensions.height}
          width={headerDimensions.width}
        />
      </div>
    ) : null;

  const serviceTitle = () =>
    settings.get(settingsParams.headerTitleVisibility) ? (
      <Text
        data-hook="header-title"
        className={classes.title}
        typography={TYPOGRAPHY.largeTitle}
        tagName={settings.get(settingsParams.headerTitleHtmlTag)}
      >
        {viewModel?.title}
      </Text>
    ) : null;

  const bookButton = () =>
    settings.get(settingsParams.headerBookButtonVisibility) &&
    viewModel.isBookable ? (
      <BookButton dataHook="header-book-button" />
    ) : null;

  return (
    <RenderLocationProvider value={MainComponents.HEADER}>
      <header
        className={st(
          classes.root,
          {
            alignment: settings.get(settingsParams.headerTitleAlignment),
            mobile,
          },
          className,
        )}
        aria-label={viewModel?.title}
        data-hook="header-wrapper"
        ref={headerRef}
      >
        {serviceImage()}
        {serviceTitle()}
        {bookButton()}
      </header>
    </RenderLocationProvider>
  );
};

export default translate()(withExperiments(Header));
