import React from 'react';
import { TextButton } from 'wix-ui-tpa/TextButton';
import { classes } from './LoadAllButton.st.css';
import { InjectedTranslateProps, translate } from 'yoshi-flow-editor-runtime';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';
import settingsParams from '../../BookingServicePage/settingsParams';

export type LoadAllButtonProps = InjectedTranslateProps & {
  onClick;
};
export const LoadAllButtonComponent = ({ onClick, t }: LoadAllButtonProps) => {
  const settings = useSettings();
  const loadAllText =
    settings.get(settingsParams.scheduleLoadAllText) ||
    t('app.scheduling.load-all-sessions.default');
  return (
    <TextButton
      className={classes.root}
      onClick={onClick}
      data-hook="load-all-button"
    >
      {loadAllText}
    </TextButton>
  );
};

export const LoadAllButton = translate()(LoadAllButtonComponent);
