import React from 'react';
import { st, classes } from './DailySessions.st.css';
import { DayDetails } from '../DayDetails/DayDetails';
import { SessionDetails } from '../SessionDetails/SessionDetails';
import Divider from '../Divider/Divider';
import { SchedulingDayViewModel } from '../../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import settingsParams from '../../BookingServicePage/settingsParams';
import { useLoadAllSessions } from '../useLoadAllSessions';
import { useTPAComponentsConfig } from '../../BookingServicePage/Widget/useTPAComponentsConfig';

export interface DailySessionsProps {
  viewModel: SchedulingDayViewModel;
}
export const DailySessions: React.FC<DailySessionsProps> = ({ viewModel }) => {
  const { date, dailySessions } = viewModel;
  const { rtl = false } = useTPAComponentsConfig();
  const { numberOfSession, LoadAllSessions } = useLoadAllSessions({
    defaultValue: settingsParams.scheduleSessionLimit,
    totalNumberOfSessions: dailySessions.length,
  });

  const showDivider = (index) => {
    const lastElementIndex =
      Math.min(numberOfSession, dailySessions.length) - 1;
    return index !== lastElementIndex ? <Divider /> : null;
  };

  return (
    <div
      className={st(classes.root, { isRTL: rtl })}
      data-hook={'daily-sessions'}
    >
      <div className={classes.day} data-hook="day-details-wrapper">
        <DayDetails date={date} />
      </div>
      <div className={classes.sessions}>
        {dailySessions
          .slice(0, numberOfSession)
          .map(({ staffName, startTime, durationText }, index) => {
            return (
              <>
                <SessionDetails
                  startTime={startTime}
                  durationText={durationText}
                  staffName={staffName}
                />
                {showDivider(index)}
              </>
            );
          })}
        <LoadAllSessions />
      </div>
    </div>
  );
};
