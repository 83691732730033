import React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import { st, classes } from './EmptyState.st.css';
import { useTPAComponentsConfig } from '../../BookingServicePage/Widget/useTPAComponentsConfig';

export type SchedulingEmptyStateProps = {
  message: string;
  dataHook?: string;
};
export const EmptyState: React.FC<SchedulingEmptyStateProps> = ({
  message,
  dataHook,
}) => {
  const { rtl = false } = useTPAComponentsConfig();
  return (
    <Text
      className={st(classes.text, { isRTL: rtl })}
      data-hook={dataHook || 'status-message'}
    >
      {message}
    </Text>
  );
};
