import React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import { classes } from './DayDetails.st.css';

export interface DayDetailsProps {
  date: string;
}
export const DayDetails: React.FC<DayDetailsProps> = ({ date }) => {
  return (
    <div className={classes.root}>
      <div>
        <Text className={classes.text} data-hook="date">
          {date}
        </Text>
      </div>
    </div>
  );
};
